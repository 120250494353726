










import Vue from "vue";
import Tags from "@/components/help/tags.vue";

export default Vue.extend<any, any, any, any>({
  name: "Modules",
  components: { Tags },
});
