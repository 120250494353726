



















import Vue from "vue";
export default Vue.extend<any, any, any, any>({
  name: "Tag",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
});
