










import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
import HelpStoreModule from "@/store/modules/help";
import Tag from "@/components/help/tag.vue";
import { tags } from "./features";

const { mapGetters: helpGetters, mapActions: helpActions } =
  createNamespacedHelpers("_HELP_MODULES");

export default Vue.extend<any, any, any, any>({
  name: "Tags",
  components: { Tag },
  data: () => ({
    newTagDialog: false,
    isLoading: false,
    newTag: {
      title: "",
      description: "",
    },
    tags,
  }),
  computed: {
    ...helpGetters(["tagPage"]),
  },
  methods: {
    ...helpActions(["fetchTags"]),
    resetForm() {
      this.newTag = {
        title: "",
        description: "",
      };
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("_HELP_MODULES")) {
      this.$store.registerModule("_HELP_MODULES", HelpStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("_HELP_MODULES");
  },
});
